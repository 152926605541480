<template>
  <div class="shopindex">
    <div
      style="
        border-bottom: 1px solid #e6e6e6;
        padding: 10px 15px;
        position: relative;
      "
    >
      <div style="display: inline-block; width: 60px">
        <img style="width: 100%" src="../../assets/guanghe.png" alt="" />
      </div>
      <div style="display: inline-block; position: absolute; top: 17px">
        <div style="font-size: 18px">光合城晔光小站</div>
        <div>便民商家</div>
      </div>
    </div>
    <div>
      <van-swipe class="my-swipe imgs" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="../../assets/lunbo1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/lunbo1.png" alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../assets/lunbo1.png" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="shopbox">
      <router-link to="/shop/shopa">
        <div style="background: #5db465">
          <van-icon class="icon-font" name="shop-collect-o" />惠民商户
        </div>
      </router-link>
      <router-link to="/shop/shopb">
        <div style="background: #5599fe">
          <van-icon class="icon-font" name="shop-o" />实体商铺
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { WeGetBusinessList, WeGetRegionList } from "@/api/yeguang";
import { GetCodeEntrysList } from "@/api/ResumeJS";
export default {
  data() {
    return {
      riKind: 0, //tab栏
      list: {}, //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
        rId: 83,
        typeKind: undefined,
      }, //查询传参
      activeKey: "0",
      hangye: [], //行业
      ridlist: [], //小区
      page: "0",
      search: "", //搜索内容
      hidden: false, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  methods: {
    // 获取分页列表
    getList() {
      WeGetBusinessList(this.listfrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          if (res.data.data.length == 0) {
            this.hidden = true;
            this.finished = true;
            this.list = {};
          } else {
            this.hidden = false;
            this.list = res.data.data;
            this.total = res.data.count;
            this.finished = true;
            // this.page = Math.ceil(res.data.count / 10);
          }
        }
      });
    },
    // hangye
    EntrysList() {
      GetCodeEntrysList({ kind: 310 }).then((res) => {
        console.log(res);
        this.hangye = res.data.data;
      });
      WeGetRegionList({ organCode: "4103110201" }).then((res) => {
        console.log(res);
        this.ridlist = res.data.data;
      });
    },
    // 下拉刷新
    onLoad() {
      // this.listfrom.page = this.listfrom.page + 1;
      // this.getList();
    },
    // tan点击切换事件
    onClick(e) {
      this.listfrom.rId = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 搜索
    onSearch(e) {
      this.listfrom.typeKind = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted() {
    this.EntrysList();
    this.getList();
  },
};
</script>
<style scoped>
.shopbox div {
  position: relative;
  width: 80%;
  height: 130px;
  text-align: center;
  line-height: 180px;
  font-size: 20px;
  color: #fff;
  margin: 30px 10%;
}
.icon-font {
  position: absolute;
  left: 50%;
  top: 20px;
  margin-left: -8%;
  font-size: 50px;
}
.imgs img {
  width: 100%;
}
</style>